<template>
  <ion-text v-if="missingOpinionUsers.length > 0">
    <h1>Félicitez votre intérimaire et faites le connaître aux autres titulaires : </h1>
  </ion-text>
  <ion-card class="flex-container-pharmacist ion-margin" v-for="missingOpinion in missingOpinionUsers" :key="missingOpinion.missionShift.id">
    <ion-text>
      <h3>Êtes-vous satisfait.e de {{missingOpinion.user.firstName}} ?</h3>
    </ion-text>
    <ProfilePicture :user="missingOpinion.user" style="margin: auto"/>
    <div class="opinion-button-wrapper">
      <ion-button
          class="opinion-button"
          fill="outline" color="white"
          @click="giveOpinion(missingOpinion, 'like')"
      >
        <ion-icon color="danger" :icon="heartSharp" style="font-size: 128px"/>
      </ion-button>

      <ion-button
          class="opinion-button opinion-button__grey"
          fill="outline"
          color="white"
          @click="giveOpinion(missingOpinion, 'dislike')"
      >
        <ion-icon color="medium" :icon="heartDislikeSharp" style="font-size: 100px"/>
      </ion-button>
    </div>

    <ion-text @click="giveOpinion(missingOpinion, 'ignore')">
      <p class="no-opinion">
        Je n'ai pas encore d'avis
      </p>
    </ion-text>

  </ion-card>
</template>

<script>
import {
  IonIcon,
  IonText,
  IonCard,
  IonButton, toastController
} from "@ionic/vue";
import store from "@/store";
import {heartSharp, heartDislikeSharp} from 'ionicons/icons';
import {updateMission} from "@/services/api";
import ProfilePicture from "@/components/ProfilePicture";
export default {
  name: 'UsersOpinion',
  computed: {
    missingOpinionUsers() {
      return store.getters.pharmacy.missingOpinionUsers;
    },
  },
  components: {
    ProfilePicture,
    IonCard,
    IonText,
    IonIcon,
    IonButton,
  },
  setup() {
    return {
      heartSharp,
      heartDislikeSharp
    }
  },
  methods: {
    async displayOpinionSent() {
      const toast = await toastController.create({
        message: "Merci, votre avis est pris en compte ✅",
        duration: 2500,
        color: 'success',
        position: 'top',
      });
      return toast.present();
    },

    giveOpinion(missionOpinion, opinion) {
      const { user, missionShift } = missionOpinion;
      updateMission(missionShift.id, {opinion})
          .then(() => {
            store.dispatch("setMissingOpinionUsers",
                store.getters.pharmacy.missingOpinionUsers
                    .filter(missionOpinion => missionOpinion.user.id !== user.id));
            this.displayOpinionSent().then();
          })
          .catch((error) => {
            console.log(error);
          });
      },
  }
}
</script>

<style scoped>

.opinion-button-wrapper {
  display: flex;
  justify-content: space-evenly;
  max-width: 300px;
  margin: 1rem auto 1rem auto;
}

.opinion-button {
  border-radius: 100%;
  width: 5rem;
  height: 5rem;
  --color: violet;
  --border-radius: 100%;
  --border-width: 1px;
  --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);
}

.opinion-button__grey {
  --color: grey;
}

.no-opinion {
  text-decoration: underline;
  cursor: pointer;
  width: 200px;
  margin: 20px auto 20px auto
}

</style>
