<template>
  <ion-card class="flex-container-pharmacist ion-margin  ion-padding-start" v-if="!isSepaSigned">
    <ion-text>
      <h2 style="color: white">Déposez votre IBAN</h2>
      <p style="color: white">Déposez votre IBAN maintenant pour pouvoir commencer à déposer des offres</p>
    </ion-text>

      <ion-button @click="showActionSheet">{{ this.buttonLabel }}</ion-button>
      <ion-button v-show="isUploaded" @click="sendIBAN">
        Valider
        <ion-spinner v-if="APIloading" name="crescent" class="ion-margin-start"></ion-spinner>
      </ion-button>
      <input type="file" id="fileInputPhoto" accept="image/*" @change="fileInputPhotoChanged($event)" style="display:none">
      <input type="file" id="fileInputPDF" accept="application/pdf" @change="fileInputPDFChanged($event)" style="display:none">

  </ion-card>
</template>

<script>
import {
  IonText,
  IonCard,
  IonButton,
  IonSpinner,
} from "@ionic/vue";
import store from "@/store";
import {Camera, CameraResultType} from '@capacitor/camera';
import {updateIBANFormData} from "@/services/api";
import {Browser} from "@capacitor/browser";
import { actionSheetController } from '@ionic/vue';
export default {
  name: 'SignSepa',
  components: {
    IonCard,
    IonText,
    IonButton,
    IonSpinner,
  },
  computed: {
    isSepaSigned() {
      return store.getters.isPharmacySignedSepa;
    },
  },
  data() {
    return {
      buttonLabel: 'Ajouter IBAN',
      IBAN: null,
      isUploaded: false,
      fileInputPhoto: null,
      fileInputPDF: null,
      APIloading: false
    };
  },
  mounted() {
    this.fileInputPhoto = this.$refs.fileInputPhoto;
    this.fileInputPDF = this.$refs.fileInputPDF;
  },
  methods: {
    updateIBANFormData,
    async goToSEPA() {
      await Browser.open({ url: store.getters.sepaUrl });
    },
    async showActionSheet(){
      const actionSheet = await actionSheetController.create({
        header: 'Choisir un fichier',
        buttons: [
          {
            text: 'Prendre une photo 🤳',
            handler: this.getPhoto
          },
          {
            text: 'Choisir une photo 📂',
            handler: this.choosePhoto
          },
          {
            text: 'Choisir un PDF 📄',
            handler: this.choosePDF
          },
          {
            text: 'Annuler',
            role: 'cancel'
          }
        ]
      });
      await actionSheet.present();
    },

    async getPhoto() {
      try {
        const result = await Camera.getPhoto({
          allowEditing: true,
          quality: 100,
          width: 200,
          height: 200,
          webUseInput: true,
          resultType: CameraResultType.Uri,
          promptLabelCancel: "Annuler",
          promptLabelPhoto: "Choisir une photo 📂",
          promptLabelPicture: "Prendre une photo 🤳",
          promptLabelHeader: "Choisir une photo de profil",
        });

        let blob = await fetch(result.webPath).then(r => r.blob());
        blob = blob.slice(0, blob.size, "image/" + result.format);
        const formData = new FormData();
        const fileName = "image_IBAN." + result.format;
        const file = new File([blob], fileName, { type: blob.type });
        formData.append('IBAN', blob);
        this.buttonLabel = fileName;
        this.isUploaded = true
        this.IBAN = file;

      } catch (e) {
        console.log(e);
      }
    },

    choosePhoto() {
      document.getElementById('fileInputPhoto').click();
    },

    choosePDF() {
      console.log("ici")
      document.getElementById('fileInputPDF').click();
    },

    fileInputPhotoChanged(event) {
      const file = event.target.files[0];
      if (file) {
        this.buttonLabel = file.name;
        this.isUploaded = true
        this.IBAN = file;
        
      }
    },
    fileInputPDFChanged(event) {
      const file = event.target.files[0];
      if (file) {
        this.buttonLabel = file.name;
        this.isUploaded = true
        this.IBAN = file;
      }
    },

    async sendIBAN(formData) {
      try {
        console.log("Envoie au serveur ...")
        this.APIloading = true
        const formData = new FormData();
        formData.append('IBAN', this.IBAN);
        updateIBANFormData(formData);
      } catch (e) {
        console.log(e);
      }
    },

  }
}
</script>

<style scoped>

</style>
