<template>
  <div class="container">
    <h2 class="bold">Mon adresse</h2>
      <ion-input
              class="medium cBlack"
              v-model="addressTyped"
              type="text"
              placeholder="Adresse"
              clear-input
              @focusin="modifyAddress"
              @input="onChangeAddress($event.target.value)"
      />
      <ion-list v-for="(address) in possibleAddresses" :key="address.id">
        <ion-item class="pointer" @click="setAddressLegit(address)">{{ address }}</ion-item>
      </ion-list>
    <div v-if="isModifiedAddress">
      <ion-button color="secondary" class="btnDelete" @click="cancelForm">Annuler</ion-button>
      <ion-button @click="validateForm" :disabled="disabledValidate">Valider</ion-button>
    </div>

  </div>
</template>

<script>
    import {
         IonItem, IonList, IonInput, IonButton
    } from "@ionic/vue"
    import {mapGetters } from 'vuex'
    import {autoCompleteAddress, getMissions, updateProfile} from "@/services/api";

    export default {
        name: 'AddressField',
        components: {
             IonItem, IonList, IonInput,  IonButton
        },
        data() {
            return {
                addressLegit: '',
                addressTyped: this.$store.getters.address,
                possibleAddresses: [],
                disabledValidate: true,
                timeStart: 0,
                isModifiedAddress: false
            }
        },
        computed: {
    ...mapGetters([
      'headers', 'user', 'address'
    ]),
        },
        methods: {
          modifyAddress() {
            this.isModifiedAddress = !this.isModifiedAddress
          },
          onChangeAddress(address){
            this.timeStart = Date.now()
            if(address.length > 3) {
              setTimeout(() => {
                if (Date.now() - this.timeStart> 100)
                  this.fetchAutoComplete()
              }, 150);
            }
          },
          setAddressLegit(address){
            this.addressLegit = address;
            this.addressTyped = address;
            this.disabledValidate = false;
            this.possibleAddresses = [];
          },
          cancelForm() {
            this.addressTyped = this.address;
            this.isModifiedAddress = false;
          },
          validateForm() {
            updateProfile({userAttributes: { address: this.addressLegit }}).then(() => {
              this.isModifiedAddress = false
              this.$emit("refresh");

            })
            
            
          },
          fetchAutoComplete() {
              const address = this.addressTyped;
              autoCompleteAddress(address).then((response) => {
                this.possibleAddresses = response.data;
              })
          },
        },
    }
</script>
<style scoped>
ion-input {
  margin: auto;
  width: 95%;
  border-style: solid;
  border-color: grey;
  border-radius: 20px;
}
</style>
