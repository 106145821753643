<template>
  <div id="container">
    <h2 v-if="acceptationStatus !== 'Accepté'">La prochaine étape avant de prendre des missions ⏱</h2>
    <div v-if="!isPhoneConfirmed" class="flex-container" >
      <div class="globalBtn">
        <ion-item @click="goConfirmationTelephone" class="itemBtn" lines="none">
          <ion-icon slot="start" color="dark" :icon="callOutline"/>
          <div class="titleBtn">
            <span class="textBtn" slot="end">VALIDER MON NUMÉRO</span>
          </div>
        </ion-item>
      </div>
    </div>

    <div v-else-if="!isProfilFull" class="flex-container" >
      <div class="globalBtn">
        <ion-item @click="goProfil" class="itemBtn" lines="none">
          <ion-icon slot="start" color="dark" :icon="peopleOutline"/>
          <div class="titleBtn">
            <span class="textBtn" slot="end">REMPLIR MON PROFIL</span>
          </div>
        </ion-item>
      </div>

    </div>

    <div v-else-if="!isCalendlyMeetTaken" class="flex-container" >
        <BookingCalendly></BookingCalendly>
    </div>

    <div v-else-if="acceptationStatus === 'En attente de validation par Alloopharma'" class="flex-container">
        <h6>Ton dossier est en attente de validation par l'équipe AllooPharma</h6>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { peopleOutline, callOutline, calendarNumberOutline } from 'ionicons/icons';
import { IonItem, IonIcon } from "@ionic/vue"
import BookingCalendly from '@/components/BookingCalendly.vue'


export default {
  name: 'InfoDashboard',
  components: { BookingCalendly,
    IonItem, IonIcon
  },
  setup() {
    return {
      peopleOutline,
      callOutline,
      calendarNumberOutline,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    isPhoneConfirmed(){ return store.getters.isPhoneConfirmed },
    isProfilFull(){ return store.getters.acceptationStatus !== "En attente de complétion du profil" },
    isCalendlyMeetTaken(){ return store.getters.isCalendlyMeetTaken },
    acceptationStatus(){ return store.getters.acceptationStatus },
  },
  methods: {
    goConfirmationTelephone(){
      router.push("/confirmation-telephone")
    },
    goProfil(){
      router.push("/informations-profil")
    },
    goCalendly() {
      router.push("/reservation-entretien-telephonique")
    }
  },
}
</script>



<style scoped>
.logo {
  width: 34px;
}
.globalBtn {
  background-color: #F7B62D !important;
  border: 1px solid #F7B62D;
  border-radius: 6px;
  padding: 0px;
  margin: 10px;
  cursor: pointer;
}

.globalBtn:hover {
  box-shadow:
          0 0.5px 2.1px -5px rgba(0, 0, 0, 0.015),
          0 1.1px 4.6px -5px rgba(0, 0, 0, 0.022),
          0 1.9px 7.6px -5px rgba(0, 0, 0, 0.027),
          0 2.9px 11.6px -5px rgba(0, 0, 0, 0.031),
          0 4.1px 16.7px -5px rgba(0, 0, 0, 0.035),
          0 5.8px 23.6px -5px rgba(0, 0, 0, 0.039),
          0 8.3px 33.4px -5px rgba(0, 0, 0, 0.043),
          0 12px 48.5px -5px rgba(0, 0, 0, 0.048),
          0 18.6px 74.8px -5px rgba(0, 0, 0, 0.055),
          0 33px 133px -5px rgba(0, 0, 0, 0.07);
}

.titleBtn {
  border-radius: 20px;
  padding: 3px;
  color : black;
  font-family: 'SFProTextSemiBold';
  font-size: 18px;
}
.textBtn {
  margin-left: 3px;
  margin-right: 3px;
}
.itemBtn {
  --background: var(--new-blue, var(--new-blue, transparent));
}
.flex-container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
ion-item {
  border-radius: 8px;
}


img{
  margin:0px;
}

h6 {
    border-bottom: 1px solid;
    width: 80%;
    padding-bottom: 10px;
}


</style>
