<template>
  <div class="flex-container-pharmacist">
    <span class="italic-description ion-text-center ion-margin-bottom">Afin de pouvoir poster des annonces, veuillez répondre aux questions du formulaire</span>
    <ion-item @click="goInformationPharmacy" class="itemBtn globalBtn" lines="none">
      <ion-icon slot="start" color="light" :icon="documentTextOutline"/>
      <div class="titleBtn">
        <span class="textBtn" slot="end">COMPLÉTER LE FORMULAIRE</span>
      </div>
    </ion-item>
    
  </div>
</template>

<script>
import { documentTextOutline } from 'ionicons/icons';
import {
  IonItem,
  IonIcon,
} from "@ionic/vue";
import router from "@/router";

export default {
  name: 'GoToInfoPharmacy',
  components: {
    IonItem,
    IonIcon,
  },
  setup() {
    return {
      documentTextOutline,
    }
  },
  methods: {
    goInformationPharmacy(){
      router.push("/informations-pharmacie")
    },
  }
}
</script>

<style scoped>

.globalBtn {
  background-color: rgb(213 169 5) !important;
  border: 1px solid #F7B62D;
  border-radius: 6px;
  padding: 0px;
  margin: auto;
}

.globalBtn:hover {
  cursor: pointer;
  box-shadow:
      0 0.5px 2.1px -5px rgba(0, 0, 0, 0.015),
      0 1.1px 4.6px -5px rgba(0, 0, 0, 0.022),
      0 1.9px 7.6px -5px rgba(0, 0, 0, 0.027),
      0 2.9px 11.6px -5px rgba(0, 0, 0, 0.031),
      0 4.1px 16.7px -5px rgba(0, 0, 0, 0.035),
      0 5.8px 23.6px -5px rgba(0, 0, 0, 0.039),
      0 8.3px 33.4px -5px rgba(0, 0, 0, 0.043),
      0 12px 48.5px -5px rgba(0, 0, 0, 0.048),
      0 18.6px 74.8px -5px rgba(0, 0, 0, 0.055),
      0 33px 133px -5px rgba(0, 0, 0, 0.07);
}

.titleBtn {
  border-radius: 20px;
  padding: 3px;
  font-family: 'SFProTextSemiBold';
  font-size: 16px;
  color: white;
}
.textBtn {
  margin-left: 3px;
  margin-right: 3px;
  color: white !important;
}
.itemBtn {
  --background: var(--new-blue, var(--new-blue, transparent));
}

.flex-container-pharmacist {
  padding: 0;
  margin: 20px 0 60px 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.italic-description {
  font-style: italic;
  font-size: 13px;
  font-weight: 600;
}

</style>